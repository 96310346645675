exports.components = {
  "component---src-pages-404-mdx": () => import("./../../../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-discord-js": () => import("./../../../src/pages/discord.js" /* webpackChunkName: "component---src-pages-discord-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-product-features-event-autocapture-js": () => import("./../../../src/pages/product-features/event-autocapture.js" /* webpackChunkName: "component---src-pages-product-features-event-autocapture-js" */),
  "component---src-pages-product-features-feature-flags-js": () => import("./../../../src/pages/product-features/feature-flags.js" /* webpackChunkName: "component---src-pages-product-features-feature-flags-js" */),
  "component---src-pages-product-features-funnels-js": () => import("./../../../src/pages/product-features/funnels.js" /* webpackChunkName: "component---src-pages-product-features-funnels-js" */),
  "component---src-pages-product-features-plugins-js": () => import("./../../../src/pages/product-features/plugins.js" /* webpackChunkName: "component---src-pages-product-features-plugins-js" */),
  "component---src-pages-product-features-retention-js": () => import("./../../../src/pages/product-features/retention.js" /* webpackChunkName: "component---src-pages-product-features-retention-js" */),
  "component---src-pages-product-features-self-hosted-js": () => import("./../../../src/pages/product-features/self-hosted.js" /* webpackChunkName: "component---src-pages-product-features-self-hosted-js" */),
  "component---src-pages-product-features-session-recording-js": () => import("./../../../src/pages/product-features/session-recording.js" /* webpackChunkName: "component---src-pages-product-features-session-recording-js" */),
  "component---src-pages-product-features-trends-js": () => import("./../../../src/pages/product-features/trends.js" /* webpackChunkName: "component---src-pages-product-features-trends-js" */),
  "component---src-pages-request-demo-js": () => import("./../../../src/pages/request_demo.js" /* webpackChunkName: "component---src-pages-request-demo-js" */),
  "component---src-pages-startups-js": () => import("./../../../src/pages/startups.js" /* webpackChunkName: "component---src-pages-startups-js" */),
  "component---src-pages-trial-js": () => import("./../../../src/pages/trial.js" /* webpackChunkName: "component---src-pages-trial-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/postTemplate.js" /* webpackChunkName: "component---src-templates-post-template-js" */)
}

