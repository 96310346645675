/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import wrapWithProvider from './wrap-with-provider'
export const wrapRootElement = wrapWithProvider

// gatsby-browser.js
require('prismjs/themes/prism-okaidia.css')
